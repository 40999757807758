import React from 'react';
import PageHeader, { HeaderTypes } from "../components/PageHeader";
import styles from "../style/pages/AboutUs.module.scss";
import {toSlug} from "../utils/StringFormatting";

const AboutUs = () => {
    const teamMembers = {
        subjects: [
            {
                name: "Active Members",
                members: [
                    {
                        name: "Doug Turnbull",
                        yearOfGraduation: "",
                        role: "Associate Professor",
                        image: "https://localify-cdn.s3.amazonaws.com/assets/about-us-content/DougPhoto.png",
                        bio: "Doug Turnbull is an Associate Professor of Computer Science at Ithaca College, leading the Localify project. His research interests include machine learning and HCI. Outside academia, Doug enjoys playing guitar, hiking, and discovering new music. His work focuses on music recommender systems and enhancing user interaction.",
                        favoriteLocalBand: "John Brown's Body",
                        favoriteLocalBandId: "4ac6a40d-1868-487e-bc57-3bbd9728d78f",
                        favoriteBandOrigin: "Ithaca, NY"
                    },
                    {
                        name: "Griffin Homan",
                        yearOfGraduation: "'25",
                        role: "Frontend Developer",
                        image: "https://localify-cdn.s3.amazonaws.com/assets/about-us-content/GriffinPhoto.png",
                        bio: "Griffin Homan, a double major in Computer Science and Emerging Media with a Minor in Music, focuses on frontend development. His skills in design and HCI from emerging media studies enhance his work on Localify. Griffin loves skiing, singing, and exploring new places.",
                        favoriteLocalBand: "Zealyn",
                        favoriteLocalBandId: "bef9001a-a650-4372-973a-65b290236f14",
                        favoriteBandOrigin: "Beverly, MA"
                    },
                    {
                        name: "Paul Gagliano",
                        yearOfGraduation: "'25",
                        role: "Backend Developer",
                        image: "https://localify-cdn.s3.amazonaws.com/assets/web_assets/paul_about_image_2.PNG",
                        bio: "Paul Gagliano is a Computer Science major and former music student at Ithaca College, with a minor in data science. He has a passion for local music, which was inspired by playing the violin in various local bands growing up. His major contributions to Localify are in the backend, recommender system, and various Dev/ML Ops.",
                        favoriteLocalBand: "Chloe Bee",
                        favoriteLocalBandId: "560642b4-fb5f-412e-b86f-242efadda828",
                        favoriteBandOrigin: "Warwick, NY"
                    },
                ]
            },
            {
                name: "Alumni",
                members: [
                    {
                        name: "April Trainor",
                        yearOfGraduation: "'24",
                        role: "Backend Developer",
                        image: "https://localify-cdn.s3.amazonaws.com/assets/about-us-content/AprilPhoto.png",
                        bio: "April Trainor, a Computer Science major, graduated in 2024. She specialized in recommendation systems, backend development, and DevOps. Her contributions to Localify included improving the interface and system performance. April enjoys creating digital art, participating in hackathons, and exploring new design trends.",
                        favoriteLocalBand: "Jojo Siwa",
                        favoriteLocalBandId: "008cd68e-dfd6-4559-b6cd-947d76adc944",
                        favoriteBandOrigin: "Omaha, NE"
                    }
                ]
            }
        ]
    };

    return (
        <div className="App-body">
            <PageHeader type={HeaderTypes.LANDING}/>
            <div className={styles["main-container"]}>
                <h1 className={styles["title"]}>About the Team</h1>
                <h6 className={styles["subtitle"]}>
                    Funded by the NSF, Localify is a collaboration between Ithaca College
                    and Cornell University, inspired by Ithaca's vibrant local music scene.
                    It helps people worldwide discover and enjoy their local music scenes.
                    Led by Ithaca College undergraduates and supervised by Professor Doug
                    Turnbull, with Cornell's support. Read on to meet our team, learn about
                    their work, and discover their favorite local artists!
                </h6>
                {teamMembers.subjects.map((subject, index) => (
                    <>
                        <h3 className={styles["subject-title"]}>{subject.name}</h3>
                        <div className={styles["team-grid"]}>
                            {subject.members.map((teamMember, index) => (
                                <div
                                    key={index}
                                    className={styles["member-card"]}
                                >
                                    <img
                                        alt={"Image of " + teamMember.name}
                                        src={teamMember.image}
                                        className={styles["member-image"]}
                                    />
                                    <div className={styles["member-info"]}>
                                        <div className={styles["basic-info"]}>
                                            <h5 className={styles["member-name"]}>{teamMember.name}</h5>
                                            <h6 className={styles["grad-year"]}>{teamMember.yearOfGraduation}</h6>
                                        </div>
                                        <h6 className={styles["member-role"]}>{teamMember.role}</h6>
                                        <h6
                                            className={styles["favorite-band"]}
                                            onClick={() =>
                                                window.open(window.location.origin + `/artist/${teamMember.favoriteLocalBandId}/${toSlug(teamMember.favoriteLocalBand)}`, "_blank")
                                            }
                                        >{teamMember.favoriteLocalBand} - {teamMember.favoriteBandOrigin}</h6>
                                        <p className={styles["bio"]}>{teamMember.bio}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </div>
        </div>
    );
};

export default AboutUs;
