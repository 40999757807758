import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import { UserCity } from "../backend/apiService";

import MenuIcon from '@mui/icons-material/Menu';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import EditLocationRoundedIcon from '@mui/icons-material/EditLocationRounded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import "../style/components/PageHeader.scss";

import banner from '../assets/banner.png';
import * as apiService from "../backend/apiService";
import {ArrowBack} from "@mui/icons-material";


export enum HeaderTypes {
    HOME = "home",
    ONBOARDING = "onboarding",
    LANDING = "landing",
    BACKABLE = "backable"
}

export enum PageTypes{
    RECOMMENDATIONS = "recommendations",
    SEARCH = "search",
    SETTINGS = "settings"
}

type Props = {
    type: HeaderTypes;
    onRecPage?: boolean;
    editLocationClicked?: () => void;
    settingsIconClicked?: () => void;
    artistInputClicked?: () => void;
    loggedIn?: boolean;
    activePage?: PageTypes;
    setNeedsRefresh?: (value: boolean) => void;
    cities?: UserCity[] | undefined;
    setShowEditCitiesModal?: () => void;
    userImage?: string;
}

const PageHeader: React.FC<Props> = ({type, onRecPage, loggedIn=false, activePage, setNeedsRefresh, cities, setShowEditCitiesModal, userImage}) => {
    const navigate = useNavigate();

    const [showNavbar, setShowNavbar] = useState<boolean>(false);
    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    const showDropdown = () => {
        let dropdownItems = document.getElementsByClassName('dropdown-item');
        let dropdownHeader = document.getElementsByClassName('dropdown-header');

        (dropdownHeader[0] as HTMLElement).style.borderBottomLeftRadius = '0';
        (dropdownHeader[0] as HTMLElement).style.borderBottomRightRadius = '0';

        for (let i = 0; i < dropdownItems.length; i++) {
            (dropdownItems[i] as HTMLElement).style.display = 'flex';
        }
    }

    const hideDropdown = () => {
        let dropdownItems = document.getElementsByClassName('dropdown-item');
        let dropdownHeader = document.getElementsByClassName('dropdown-header');

        (dropdownHeader[0] as HTMLElement).style.borderBottomLeftRadius = '30px';
        (dropdownHeader[0] as HTMLElement).style.borderBottomRightRadius = '30px';

        for (let i = 0; i < dropdownItems.length; i++) {
            (dropdownItems[i] as HTMLElement).style.display = 'none';
        }
    }

    const handleCityChange = (cityId: string) => {
        apiService.patchUserCities(cityId, true).then(() => {
            if (setNeedsRefresh) {
                setNeedsRefresh(true);
            }
        })
    }

    if (type === HeaderTypes.LANDING) {
        return (
            <nav className="navbar">
                <div className="header-real-container">
                    <div
                        style={{display: "flex", alignItems: "center", position: "relative", height:"100%"}}
                        onClick={() => navigate("/")}
                    >
                        <img src={banner} alt="" className="header-banner" />
                    </div>
                    <div className="menu-icon" onClick={handleShowNavbar}>
                        <MenuIcon />
                    </div>
                    <div className="nav-elements">
                        <ul>
                            <li
                                className="nav-bar-link"
                                onClick={() => window.open("https://blog.localify.org", "_blank")}
                            >
                                <NavLink to="">Blog</NavLink>
                            </li>
                            <li className="nav-bar-link">
                                <NavLink to="/team/about-us">About Us</NavLink>
                            </li>
                            <li className="nav-bar-button">
                                {loggedIn ? <NavLink to="/recommendations">Continue to Localify</NavLink> :
                                    <NavLink to="/login">Login</NavLink>}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    } else if (type === HeaderTypes.HOME) {

        return (
            <>
                <nav className="navbar">
                    <div className="header-real-container">
                        <div style={{display: "flex", alignItems: "center", position: "relative"}}
                             onClick={() => {
                                 if (!onRecPage)
                                    navigate("/recommendations");
                             }}>
                            <img src={banner} alt="" className="header-banner"/>
                        </div>
                        <div className="menu-icon" onClick={handleShowNavbar}>
                            <MenuIcon/>
                        </div>
                        <div className={`nav-elements  ${showNavbar && 'active'}`}>
                            <ul style={{padding: 0}}>
                                {(setShowEditCitiesModal &&
                                <li className={'nav-dropdown'}>
                                    <div className={'dropdown'}>
                                        <ul className={`cities-dropdown`} onMouseEnter={showDropdown}
                                            onMouseLeave={hideDropdown}>
                                            <li className={'dropdown-header'}>My Cities <FontAwesomeIcon
                                                icon={faChevronDown} size={"2xs"}/></li>
                                            {cities &&
                                                cities.slice(0, 3).map((city, index) => (
                                                    <li key={index} className={'dropdown-item'}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCityChange(city.id)
                                                        }}>
                                                        {`${city.name}, ${city.zoneCode}`}
                                                    </li>
                                                ))}
                                            <li className={'dropdown-item'}
                                                onClick={() => setShowEditCitiesModal()}>
                                                <EditLocationRoundedIcon/>Edit all cities
                                            </li>
                                        </ul>
                                    </div>
                                </li>)}
                                <li className={`nav-item ${activePage === PageTypes.RECOMMENDATIONS ? 'active-page' : ''}`}
                                    onClick={() => navigate("/recommendations")}>
                                    <HomeRoundedIcon/>
                                    <p>Home</p>
                                </li>
                                <li className={`nav-item ${activePage === PageTypes.SEARCH ? 'active-page' : ''}`}
                                    onClick={() => navigate("/search")}>
                                    <SearchRoundedIcon/>
                                    <p>Search</p>
                                </li>
                                <li className={`nav-item ${activePage === PageTypes.SETTINGS ? 'active-page' : ''}`}
                                    onClick={() => navigate("/user/settings")}>
                                    <div
                                        className={`profile-image nav-icon ${activePage === PageTypes.SETTINGS ? 'active-page' : ''}`}>
                                        <img alt={''} src={userImage ? userImage : noImage}/>
                                    </div>
                                    <p>My Profile</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        )
    } else if (type === HeaderTypes.BACKABLE) {

        return (
            <>
                <nav className="navbar">
                    <div className="header-real-container">
                        <div className="back-title-container">
                            <ArrowBack
                                className="back-arrow"
                                onClick={() => navigate(-1)}
                            />
                            <div style={{display: "flex", alignItems: "center", position: "relative"}}
                                 onClick={() => navigate("/recommendations")}>
                                <img src={banner} alt="" className="header-banner"/>
                            </div>
                        </div>
                        <div className="menu-icon" onClick={handleShowNavbar}>
                            <MenuIcon/>
                        </div>
                        <div className={`nav-elements  ${showNavbar && 'active'}`}>
                            <ul style={{padding: 0}}>
                                {(setShowEditCitiesModal &&
                                    <li className={'nav-dropdown'}>
                                        <div className={'dropdown'}>
                                            <ul className={`cities-dropdown`} onMouseEnter={showDropdown}
                                                onMouseLeave={hideDropdown}>
                                                <li className={'dropdown-header'}>My Cities <FontAwesomeIcon
                                                    icon={faChevronDown} size={"2xs"}/></li>
                                                {cities &&
                                                    cities.slice(0, 3).map((city, index) => (
                                                        <li key={index} className={'dropdown-item'}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleCityChange(city.id)
                                                            }}>
                                                            {`${city.name}, ${city.zoneCode}`}
                                                        </li>
                                                    ))}
                                                <li className={'dropdown-item'}
                                                    onClick={() => setShowEditCitiesModal()}>
                                                    <EditLocationRoundedIcon/>Edit all cities
                                                </li>
                                            </ul>
                                        </div>
                                    </li>)}
                                <li className={`nav-item ${activePage === PageTypes.RECOMMENDATIONS ? 'active-page' : ''}`}
                                    onClick={() => navigate("/recommendations")}>
                                    <HomeRoundedIcon/>
                                    <p>Home</p>
                                </li>
                                <li className={`nav-item ${activePage === PageTypes.SEARCH ? 'active-page' : ''}`}
                                    onClick={() => navigate("/search")}>
                                    <SearchRoundedIcon/>
                                    <p>Search</p>
                                </li>
                                <li className={`nav-item ${activePage === PageTypes.SETTINGS ? 'active-page' : ''}`}
                                    onClick={() => navigate("/user/settings")}>
                                    <div
                                        className={`profile-image nav-icon ${activePage === PageTypes.SETTINGS ? 'active-page' : ''}`}>
                                        <img alt={''} src={userImage ? userImage : noImage}/>
                                    </div>
                                    <p>My Profile</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        )
    }

    else if (type === HeaderTypes.ONBOARDING) {

        return (
            <nav className="navbar" style={{position: "static"}}>
                <div className="header-real-container" style={{justifyContent: "center"}}>
                    <div style={{display: "flex", alignItems: "center", position: "relative", width: "100%", justifyContent: "center"}}>
                        <img src={banner} alt="" className="header-banner" style={{cursor: "default"}} onClick={() => navigate('/')}/>
                    </div>
                </div>
            </nav>
        )
    }


    else {
        return null;
    }
}

export default PageHeader;
