import React from 'react';
import globalColors from "../style/globalColors";
const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

type Props = {
    id: string;
    name: string;
    image?: string;
    selected?: boolean;
    size?: string;
    onClick?: (id: string, name: string) => void;
}

const ArtistCard: React.FC<Props> = (props) => {
    const {id, name, image, selected, size, onClick} = props;

    return (
        <div onClick={() => {
            if (onClick)
                onClick(id, name)
        }}>
            <img
                src={image ? image : noImage}
                style={{
                    width: size ? size : (selected ? 185 : 200),
                    height: size ? size : (selected ? 185 : 200),
                    borderRadius: 15,
                    borderWidth: selected ? 7.5 : 0,
                    borderColor: globalColors.primary,
                    borderStyle: 'solid', // Add this property
                }}
            />
            <h1 style={{textAlign: "center", fontSize: 16, color: globalColors.white}}>{name}</h1>
        </div>
    )
}

export default ArtistCard;
