import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import * as apiService from "../backend/apiService";
import {
    ArtistRecResponse,
    CitySelectionTimeFrame,
    CitySortBy,
    EventRecResponse,
    PlaylistResponse,
    UserCity
} from "../backend/apiService";
import ArtistRecommendationCard from "../components/ArtistRecommendationCard";
import EventRecommendationCard from "../components/EventRecommendationCard";
import ErrorModal from "../components/ErrorModal";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import PageHeader, { HeaderTypes, PageTypes } from "../components/PageHeader";
import styles from "../style/pages/Recommendations.module.scss";
import * as stringFormatting from "../utils/StringFormatting";

import ExploreIcon from '@mui/icons-material/Explore';
import CircularProgress from "@mui/material/CircularProgress";
import { CalendarMonth, ExpandMore, Feedback, Sort } from "@mui/icons-material";
import CalendarModal from "../components/CalendarModal";

enum GeneralFilter {
    ARTISTS = 'Artists',
    EVENTS = 'Events'
}

enum ErrorTypes {
    NO_USER_CITIES = "No user cities",
    NO_USER_SEEDS = "No user seeds",
    ARTIST_RANGE_TOO_SMALL = "Artist range too small",
    EVENT_RANGE_TOO_SMALL = "Event range too small",
    FAILED_TO_FETCH_ARTIST_RECS = "Failed to fetch artist recommendations",
    FAILED_TO_FETCH_EVENT_RECS = "Failed to fetch event recommendations",
    FAILED_TO_FAVORITE = "Failed to favorite"
}

const Recommendations = () => {
    const navigate = useNavigate();
    const { type } = useParams();

    const [loading, setLoading] = useState(true);
    const [artistRecs, setArtistRecs] = useState<ArtistRecResponse[] | undefined>();
    const [eventRecs, setEventRecs] = useState<EventRecResponse[] | undefined>();
    const [currentCity, setCurrentCity] = useState<UserCity | undefined>();
    const [otherCities, setOtherCities] = useState<UserCity[]>([]);
    const [currentFilter, setCurrentFilter] = useState(GeneralFilter.ARTISTS);
    const [errorType, setErrorType] = useState<ErrorTypes | undefined>();

    const [showEditCitiesModal, setShowEditCitiesModal] = useState(false);
    const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (type) {
            if (type === "artists") {
                setCurrentFilter(GeneralFilter.ARTISTS);
            } else if (type === "events") {
                setCurrentFilter(GeneralFilter.EVENTS);
            }
        }
    }, [type]);

    useEffect(() => {
        if (needsRefresh) {
            fetchUserCities().then(cityId => {
                if (cityId) {
                    fetchRecommendations(cityId).then();
                    apiService.fetchUserCityPlaylist(cityId).then();
                    setNeedsRefresh(false);
                }
            });
        }
    }, [needsRefresh]);

    useEffect(() => {
        fetchUserCities().then(cityId => {
            if (cityId) {
                fetchRecommendations(cityId).then();
            }
        });
    }, []);

    useEffect(() => {
        if (currentCity) {
            if (currentFilter === GeneralFilter.ARTISTS)
                window.history.replaceState({}, '', `/recommendations?type=artists&city=${currentCity.id}`);
            else if (currentFilter === GeneralFilter.EVENTS)
                window.history.replaceState({}, '', `/recommendations?type=events&city=${currentCity.id}`);
        }
    }, [currentFilter, currentCity]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchRecommendations = async (cityId?: string) => {
        setLoading(true);
        const id = cityId || currentCity?.id;
        if (id) {
            try {
                await Promise.all([
                    fetchArtistRecommendations(id),
                    fetchEventRecommendations(id)
                ]);
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }
    };

    const fetchUserCities = async () => {
        try {
            setLoading(true);
            const cityResponse = await apiService.fetchUserCities();
            setCurrentCity(cityResponse.current);
            setOtherCities(cityResponse.others || []);
            return cityResponse.current.id;
        } catch {
            setErrorType(ErrorTypes.NO_USER_CITIES);
        } finally {
            setLoading(false);
        }
    };

    const fetchArtistRecommendations = async (cityId: string) => {
        try {
            const recResponse = await apiService.fetchArtistRecommendations(cityId);
            setArtistRecs(recResponse);
        } catch (error: any) {
            if (error.message === "No user seeds") {
                setErrorType(ErrorTypes.NO_USER_SEEDS);
            } else if (error.message === "Radius too small") {
                setErrorType(ErrorTypes.ARTIST_RANGE_TOO_SMALL);
            } else {
                setErrorType(ErrorTypes.FAILED_TO_FETCH_ARTIST_RECS);
            }
        }
    };

    const fetchEventRecommendations = async (cityId: string) => {
        try {
            const recResponse = await apiService.fetchEventRecommendations(cityId, {});
            setEventRecs(recResponse);
        } catch (error: any) {
            if (error.message === "No user seeds") {
                setErrorType(ErrorTypes.NO_USER_SEEDS);
            } else if (error.message === "Radius too small") {
                setErrorType(ErrorTypes.EVENT_RANGE_TOO_SMALL);
            } else {
                setErrorType(ErrorTypes.FAILED_TO_FETCH_EVENT_RECS);
            }
        }
    };

    const handleCloseEditCitiesModal = () => {
        setShowEditCitiesModal(false);
        setNeedsRefresh(true);
    };

    const handleBookmarkError = () => {
        setErrorType(ErrorTypes.FAILED_TO_FAVORITE);
    };

    const fetchErrorMessage = () => {
        switch (errorType) {
            case ErrorTypes.NO_USER_CITIES:
                return "It appears you have no cities selected. In order to get recommendations, you need to select a city.";
            case ErrorTypes.NO_USER_SEEDS:
                return "It appears you have no artists selected to provide you artist & event recommendations.";
            case ErrorTypes.ARTIST_RANGE_TOO_SMALL:
                return "Artist range too small.";
            case ErrorTypes.FAILED_TO_FETCH_ARTIST_RECS:
                return "Failed to fetch artist recommendations.";
            case ErrorTypes.FAILED_TO_FETCH_EVENT_RECS:
                return "Failed to fetch event recommendations.";
            case ErrorTypes.FAILED_TO_FAVORITE:
                return "Failed to favorite artist or event.";
            default:
                return "An error has occurred.";
        }
    };

    const handleSortByChange = async (sortOption: CitySortBy) => {
        if (currentCity?.sortBy === sortOption)
            setShowDropdown(false);
        else {
            let newEvents;
            if (currentCity) {
                setLoading(true);
                try {
                    newEvents = await apiService.fetchEventRecommendations(currentCity.id, { sortBy: sortOption });
                    setEventRecs(newEvents);
                    await fetchUserCities();
                } catch (error) {
                    console.log(error);
                }
            }
        }
        setShowDropdown(false);
    };

    const onDateRangeChange = async (dateRange: CitySelectionTimeFrame, startDate: number | undefined, endDate: number | undefined) => {
        setLoading(true);
        setShowCalendarModal(false);
        try {
            let newEvents;
            if (currentCity) {
                if (startDate && endDate) {
                    newEvents = await apiService.fetchEventRecommendations(currentCity.id, {
                        timeFrame: dateRange,
                        start: startDate,
                        end: endDate
                    });
                } else {
                    newEvents = await apiService.fetchEventRecommendations(currentCity.id, { timeFrame: dateRange });
                }
                setEventRecs(newEvents);
                await fetchUserCities();
            }
        } catch (error) {
            console.log("error");
        } finally {
            setLoading(false);
        }
    }

    const getTimeFrameText = () => {
        if (!currentCity) return '';

        switch (currentCity.timeFrame) {
            case CitySelectionTimeFrame.DAYS_7:
                return 'Next 7 Days';
            case CitySelectionTimeFrame.DAYS_30:
                return 'Next 30 Days';
            case CitySelectionTimeFrame.DAYS_90:
                return 'Next 90 Days';
            case CitySelectionTimeFrame.ALL:
                return 'All Future Events';
            case CitySelectionTimeFrame.CUSTOM:
                if (currentCity.startDate && currentCity.endDate)
                    return `${formatMilliseconds(currentCity.startDate)} - ${formatMilliseconds(currentCity.endDate)}`;
                else
                    return '';
            default:
                return '';
        }
    }

    function formatMilliseconds(ms: number): string {
        const date = new Date(ms);

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];

        // Function to get the correct ordinal suffix
        function getOrdinalSuffix(day: number): string {
            if (day > 3 && day < 21) return 'th'; // For 11th to 20th
            switch (day % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        }

        const ordinalSuffix = getOrdinalSuffix(day);

        return `${month}. ${day}${ordinalSuffix}`;
    }

    const fetchTryAgainText = () => {
        if (errorType === ErrorTypes.NO_USER_CITIES) {
            return "Select a City";
        } else if (errorType === ErrorTypes.NO_USER_SEEDS) {
            return "Select Artists";
        } else {
            return "Try again";
        }
    }

    const handleRetry = () => {
        if (errorType === ErrorTypes.NO_USER_CITIES) {
            navigate("/add/location")
        } else if (errorType === ErrorTypes.NO_USER_SEEDS) {
            navigate("/onboarding/artists");
        } else if (currentCity?.id) {
            if (errorType === ErrorTypes.FAILED_TO_FETCH_EVENT_RECS) {
                fetchEventRecommendations(currentCity.id).then(() => setErrorType(undefined));
            } else if (errorType === ErrorTypes.FAILED_TO_FETCH_ARTIST_RECS) {
                fetchArtistRecommendations(currentCity.id).then(() => setErrorType(undefined));
            }
        }
    };

    const handleAudioPlay = (audio: HTMLAudioElement) => {
        if (currentAudio && currentAudio !== audio) {
            currentAudio.pause();
        }
        setCurrentAudio(audio);
    };

    return (
        <div className={styles['App-body']}>
            <div className={styles["top-right-icons"]}>
                {currentCity && (
                    <>
                        <div
                            className={styles["top-right-icon-container"]}
                            onClick={() => navigate(`/city/${currentCity.id}/${stringFormatting.toSlug(currentCity.name)}`)}
                        >
                            <ExploreIcon className={styles["top-right-icon-left"]} />
                            <h6 className={styles["top-right-icon-text"]}>{`About ${currentCity.name}`}</h6>
                        </div>
                        {currentFilter === GeneralFilter.EVENTS &&
                            <div
                                className={`${styles["top-right-icon-container"]} ${showDropdown ? styles["show"] : ""}`}
                                onClick={() => setShowDropdown(!showDropdown)}
                                ref={buttonRef}
                            >
                                <Sort className={styles["top-right-icon-left"]} />
                                <h6 className={styles["top-right-icon-text"]}>Sort
                                    By: {currentCity.sortBy === CitySortBy.DATE ? "Date" : "% Match"}</h6>
                                <ExpandMore
                                    className={`${styles["top-right-icon-right"]} ${showDropdown ? styles["rotate"] : ""}`} />
                                {showDropdown && (
                                    <div ref={dropdownRef}
                                         className={`${styles["dropdown-menu"]} ${showDropdown ? styles["show"] : ""}`}>
                                        <h6 className={styles["dropdown-item"]}
                                            onClick={() => handleSortByChange(CitySortBy.SCORE)}>% Match</h6>
                                        <h6 className={styles["dropdown-item"]}
                                            onClick={() => handleSortByChange(CitySortBy.DATE)}>Date</h6>
                                    </div>
                                )}
                            </div>
                        }
                        {currentFilter === GeneralFilter.EVENTS &&
                            <div
                                className={styles["top-right-icon-container"]}
                                onClick={() => setShowCalendarModal(true)}
                            >
                                <CalendarMonth className={styles["top-right-icon-left"]} />
                                <h6 className={styles["top-right-icon-text"]}>{getTimeFrameText()}</h6>
                                <ExpandMore className={styles["top-right-icon-right"]} />
                            </div>
                        }
                        {currentCity?.spotifyPlaylist &&
                            (
                                <div
                                className={styles["top-right-icon-container"]}
                                onClick={() => window.open(`https://open.spotify.com/playlist/${currentCity?.spotifyPlaylist}`, "_blank")}
                                >
                                    <img
                                        src="https://localify-cdn.s3.amazonaws.com/assets/web_assets/Spotify_Primary_Logo_RGB_Green.png"
                                        alt="Spotify Brand Logo"
                                        title={`Localify ${currentCity.name} Playlist`}
                                        className={styles["top-right-icon-left"]}
                                    />
                                    <h6 className={styles["top-right-icon-text"]}>View <i>Your</i> {currentCity.name} Playlist</h6>
                                </div>
                            )
                        }
                    </>
                )}
            </div>
            {(errorType !== undefined && errorType !== ErrorTypes.ARTIST_RANGE_TOO_SMALL && errorType !== ErrorTypes.EVENT_RANGE_TOO_SMALL) &&
                <ErrorModal
                    errorMessage={fetchErrorMessage()}
                    onTryAgain={handleRetry}
                    tryAgainMessage={fetchTryAgainText()}
                    loading={loading}
                    onClose={() => {
                        if (errorType !== ErrorTypes.FAILED_TO_FAVORITE) navigate('/');
                        setErrorType(undefined);
                    }}
                    onCloseMessage={errorType === ErrorTypes.FAILED_TO_FAVORITE ? "Close" : "Back to Home"}
                />}
            <PageHeader
                type={HeaderTypes.HOME}
                onRecPage={true}
                artistInputClicked={() => navigate('/search')}
                editLocationClicked={() => setShowEditCitiesModal(true)}
                settingsIconClicked={() => navigate('/user/settings')}
                activePage={PageTypes.RECOMMENDATIONS}
                setNeedsRefresh={setNeedsRefresh}
                cities={currentCity ? [currentCity, ...otherCities] : otherCities}
                setShowEditCitiesModal={() => setShowEditCitiesModal(true)}
            />
            <div className={"bottom-right-icons"}>
                <div
                    className={"bottom-right-icon-container"}
                    onClick={() => window.open(window.location.origin + "/feedback-form", "_blank")}
                >
                    <Feedback className={"bottom-right-icon"} />
                </div>
            </div>
            {loading ? <CircularProgress className={styles["circular-progress"]} /> :
                <div className={styles['page-layout']}>
                    {currentCity &&
                        <div>
                            <div className={styles['city-header']}>
                                <h5>Your recommendations from</h5>
                                <h1>
                                    {`${currentCity.name}, ${currentCity.zoneCode}`}
                                </h1>
                                {Object.values(GeneralFilter).map((filter, index) => (
                                    <h6
                                        key={index}
                                        className={currentFilter === filter ? styles.active : ''}
                                        onClick={() => setCurrentFilter(filter)}
                                    >
                                        {filter}
                                    </h6>
                                ))}
                            </div>
                            {currentFilter === GeneralFilter.ARTISTS ?
                                <div className={styles['recs-container']}>
                                    {errorType === ErrorTypes.NO_USER_SEEDS ? (
                                        <div>
                                            <h4 className={styles['no-recs-message']}>No Artists to recommend local
                                                artists</h4>
                                            <div className={styles['flex-center']}>
                                                <h6
                                                    className={styles['select-city-button']}
                                                    onClick={() => navigate('/onboarding/artists')}
                                                >Select Artists</h6>
                                            </div>
                                        </div>
                                    ) : errorType === ErrorTypes.ARTIST_RANGE_TOO_SMALL ? (
                                        <div>
                                            <h4 className={styles['no-recs-message']}>Your city radius is too small to
                                                find local artists.</h4>
                                            <div className={styles['flex-center']}>
                                                <h6
                                                    className={styles['select-city-button']}
                                                    onClick={() => navigate(`/edit-radius/location?id=${currentCity.id}&radius=${currentCity.radius}`)}
                                                >Adjust Range</h6>
                                            </div>
                                        </div>
                                    ) : errorType === ErrorTypes.FAILED_TO_FETCH_ARTIST_RECS ? (
                                        <div>
                                            <h4 className={styles['no-recs-message']}>Failed to Fetch Artist
                                                Recommendations</h4>
                                            <div className={styles['flex-center']}>
                                                <h6
                                                    className={styles['select-city-button']}
                                                    onClick={handleRetry}>Try Again
                                                </h6>
                                            </div>
                                        </div>
                                    ) : (!artistRecs || artistRecs.length === 0) ? (
                                        <div>
                                            <h4 className={styles['no-recs-message']}>No Artist Recommendations for this
                                                City</h4>
                                            <div className={styles['flex-center']}>
                                                <h6 className={styles['select-city-button']}
                                                    onClick={() => setShowEditCitiesModal(true)}>
                                                    Select New City
                                                </h6>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles['recs-grid']}>
                                            {artistRecs.map((artist, i) => (
                                                <div
                                                    key={i}
                                                    onClick={() => navigate(`/artist/${artist.id}/${artist.name}`)}
                                                >
                                                    <ArtistRecommendationCard
                                                        artist={artist}
                                                        handleError={handleBookmarkError}
                                                        onPlay={handleAudioPlay}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div> :
                                <div className={styles['recs-container']}>
                                    {errorType === ErrorTypes.NO_USER_SEEDS ? (
                                        <div>
                                            <h4 className={styles['no-recs-message']}>No Artists to recommend
                                                events</h4>
                                            <div className={styles['flex-center']}>
                                                <h6
                                                    className={styles['select-city-button']}
                                                    onClick={() => navigate('/onboarding/artists')}
                                                >Select Artists</h6>
                                            </div>
                                        </div>
                                    ) : errorType === ErrorTypes.EVENT_RANGE_TOO_SMALL ? (
                                        <div>
                                            <h4 className={styles['no-recs-message']}>Your city radius is too small to
                                                find local events.</h4>
                                            <div className={styles['flex-center']}>
                                                <h6
                                                    className={styles['select-city-button']}
                                                    onClick={() => navigate(`/edit-radius/location?id=${currentCity.id}&radius=${currentCity.radius}`)}
                                                >Adjust Range</h6>
                                            </div>
                                        </div>
                                    ) : errorType === ErrorTypes.FAILED_TO_FETCH_EVENT_RECS ? (
                                        <div>
                                            <h4 className={styles['no-recs-message']}>Failed to Fetch Event
                                                Recommendations</h4>
                                            <div className={styles['flex-center']}>
                                                <h6 className={styles['select-city-button']} onClick={handleRetry}>Try
                                                    Again</h6>
                                            </div>
                                        </div>
                                    ) : (!eventRecs || eventRecs.length === 0) ? (
                                        <div>
                                            <h4 className={styles['no-recs-message']}>No Event Recommendations for this
                                                City</h4>
                                            <div className={styles['flex-center']}>
                                                <h6 className={styles['select-city-button']}
                                                    onClick={() => setShowEditCitiesModal(true)}>
                                                    Select New City
                                                </h6>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles['recs-grid']}>
                                            {eventRecs.map((event, i) => (
                                                <div
                                                    key={i}
                                                    onClick={() => navigate(`/event/${event.id}/${stringFormatting.toSlug(event.name)}`)}
                                                >
                                                    <EventRecommendationCard
                                                        event={event}
                                                        handleError={handleBookmarkError}
                                                        onPlay={handleAudioPlay}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            {showEditCitiesModal &&
                <ListOfCitiesModal onClose={handleCloseEditCitiesModal} />
            }
            {(showCalendarModal && currentCity) &&
                <CalendarModal
                    onClose={() => setShowCalendarModal(false)}
                    initialDateRange={currentCity.timeFrame}
                    onSubmit={onDateRangeChange}
                />
            }
        </div>
    );
};

export default Recommendations;
