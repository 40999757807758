import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import PageHeader, {HeaderTypes, PageTypes} from "../components/PageHeader";
import {useNavigate} from "react-router-dom";
import styles from "../style/pages/Search.module.scss";
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import * as apiService from "../backend/apiService";
import {ArtistResponse, CityResponse, EventResponse, VenueResponse} from "../backend/apiService";
import * as StringFormatting from "../utils/StringFormatting";

import CityIcon from "../assets/CityIcon.png";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import {Feedback} from "@mui/icons-material";

enum FilterTypes {
    ARTISTS = "Artists",
    CITIES = "Cities",
    VENUES = "Venues"
}

const Search: React.FC = () => {
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);

    const [inputValue, setInputValue] = useState<string>('');
    const [artists, setArtists] = useState<ArtistResponse[]>([]);
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [venues, setVenues] = useState<VenueResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showEditCitiesModal, setShowEditCitiesModal] = useState<boolean>(false);
    const [currentFilter, setCurrentFilter] = useState<FilterTypes>(FilterTypes.ARTISTS);

    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setLoading(true);
        apiService.fetchSearch(event.target.value, true).then(response => {
            setArtists(response.artists);
            setCities(response.cities);
            setVenues(response.venues);
            setLoading(false);
        }).catch((error) => {
            if (error.name !== "CanceledError") //If it's not just a canceled early error, then there's something else wrong
                setLoading(false); // Set loading equal to false
        })
    }

    return (
        <div className={styles['main-container']}>
            <PageHeader
                type={HeaderTypes.HOME}
                artistInputClicked={() => navigate('/search')}
                editLocationClicked={() => setShowEditCitiesModal(true)}
                settingsIconClicked={() => navigate('/user/settings')}
                activePage={PageTypes.SEARCH}
            />
            <div className={"bottom-right-icons"}>
                <div
                    className={"bottom-right-icon-container"}
                    onClick={() => window.open(window.location.origin + "/feedback-form", "_blank")}
                >
                    <Feedback className={"bottom-right-icon"}/>
                </div>
            </div>

            <div className={styles['input-wrapper']}>
                <SearchIcon className={styles['input-icon']}/>
                <input
                    ref={inputRef}
                    className={styles['input']}
                    placeholder="Search for Artists, Events, and Cities"
                    value={inputValue}
                    onChange={onTextChange}
                />
                {loading && <CircularProgress className={styles['loading-indicator']} size={24} thickness={5}/>}
            </div>
            <div className={styles["filter-container"]}>
                {Object.values(FilterTypes).map((filter, index) => (
                    <h6
                        key={index}
                        className={`${styles["filter"]} ${filter === currentFilter ? styles["active"] : ""}`}
                        onClick={() => setCurrentFilter(filter)}
                    >{filter}</h6>
                ))}
            </div>

            {/* Render artist section if current filter is "Artists" */}
            {(!loading && currentFilter === FilterTypes.ARTISTS) &&
                <div className={styles['grid-section']}>
                    {artists.length > 0 || inputValue.length === 0 ? (
                        <div className={styles['grid-container']}>
                            {artists.map((artist, index) => (
                                <div
                                    key={index}
                                    className={styles['grid-item']}
                                    onClick={() => navigate(`/artist/${artist.id}/${StringFormatting.toSlug(artist.name)}`)}
                                >
                                    <img src={artist.image || noImage} alt=""/>
                                    <h6>{artist.name}</h6>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <h6 className={styles["no-results"]}>No artists available.</h6>
                    )}
                </div>
            }

            {/* Render city section if current filter is "Cities" */}
            {(!loading && currentFilter === FilterTypes.CITIES) &&
                <div className={styles['grid-section']}>
                    {cities.length > 0 || inputValue.length === 0 ? (
                        <div className={styles['grid-container']}>
                            {cities.map((city, index) => (
                                <div
                                    key={index}
                                    className={styles['grid-item']}
                                    onClick={() => navigate(`/city/${city.id}/${StringFormatting.toSlug(city.name)}`)}
                                >
                                    <img src={CityIcon} alt="" className={styles['city-icon']}/>
                                    <h6>{city.name}, {city.zoneCode ? city.zoneCode : city.countryCode}</h6>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <h6 className={styles["no-results"]}>No cities available.</h6>
                    )}
                </div>
            }

            {/* Render venue section if current filter is "Venues" */}
            {(!loading && currentFilter === FilterTypes.VENUES) &&
                <div className={styles['grid-section']}>
                    {venues.length > 0 || inputValue.length === 0 ? (
                        <div className={styles['grid-container']}>
                            {venues.map((venue, index) => (
                                <div
                                    key={index}
                                    className={styles['grid-item']}
                                    onClick={() => navigate(`/venue/${venue.id}/${StringFormatting.toSlug(venue.name)}`)}
                                >
                                    <img src={CityIcon} alt="" className={styles['city-icon']}/>
                                    <h6>{venue.name}</h6>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <h6 className={styles["no-results"]}>No venues available.</h6>
                    )}
                </div>
            }

            {/* Render edit cities modal */}
            {showEditCitiesModal &&
                <ListOfCitiesModal
                    onClose={() => setShowEditCitiesModal(false)}
                />
            }
        </div>
    )
}

export default Search;
