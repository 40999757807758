import React from 'react';
import {useNavigate} from "react-router-dom";
import PageHeader, {HeaderTypes} from "../components/PageHeader";

const banner = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/banner-no-padding.png';

const Blog = () => {
    const navigate = useNavigate();

    return (
        <div className="App-body">
            <PageHeader type={HeaderTypes.LANDING} />

        </div>
    )
}

export default Blog;
