import React, { useEffect, useState, useRef } from 'react';
import { UserCity } from "../backend/apiService";
import styles from "../style/components/ListOfCitiesModal.module.scss";

import { useNavigate } from "react-router-dom";
import * as apiService from "../backend/apiService";

import LocationCityIcon from '@mui/icons-material/LocationCity';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
    initialCurrentCity?: UserCity;
    initialCities?: UserCity[];
    onClose: () => void;
}

const ListOfCitiesModal: React.FC<Props> = ({ initialCurrentCity, initialCities, onClose }) => {
    const navigate = useNavigate();
    const [currentCity, setCurrentCity] = useState<UserCity | undefined>(initialCurrentCity);
    const [cities, setCities] = useState<UserCity[]>(initialCities || []);
    const [menuVisible, setMenuVisible] = useState<number | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const menuRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        document.body.classList.add('no-scroll');

        if (!initialCurrentCity) {
            setLoading(true);
            apiService.fetchUserCities().then(resp => {
                setCurrentCity(resp.current);
                setCities(resp.others);
                setLoading(false);
            });
        }

        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;
            if (
                modalRef.current && !modalRef.current.contains(target) &&
                !menuRefs.current.some(menuRef => menuRef && menuRef.contains(target))
            ) {
                setMenuVisible(null);
            } else if (modalRef.current && modalRef.current.contains(target) &&
                !menuRefs.current.some(menuRef => menuRef && menuRef.contains(target))) {
                setMenuVisible(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.body.classList.remove('no-scroll');
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEdit = (index: number) => {
        if (index === -1 && currentCity) {
            navigate(`/edit-radius/location?id=${currentCity.id}&radius=${currentCity.radius}`);
        } else {
            navigate(`/edit-radius/location?id=${cities[index].id}&radius=${cities[index].radius}`)
        }
    };

    const handleDelete = (index: number) => {
        apiService.deleteUserCity(cities[index].id).then(() => {
            setMenuVisible(null);
            setCities(cities.filter((_, i) => i !== index));
        }).catch(error => console.log(error));
    };

    const handleMakeCurrent = (index: number) => {
        if (currentCity && cities[index]) {
            apiService.patchUserCities(cities[index].id, true).then(() => {
                setMenuVisible(null);
                const newCities = [...cities];
                const newCurrentCity = newCities.splice(index, 1)[0];
                setCurrentCity(newCurrentCity);
                setCities([currentCity, ...newCities]);
            });
        }
        setMenuVisible(null);
    };

    const handleAddNewCity = () => {
        navigate("/add/location");
    }

    const toggleMenu = (index: number, e: React.MouseEvent) => {
        e.stopPropagation();
        setMenuVisible(menuVisible === index ? null : index);
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div
                className={styles.modal}
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}
            >
                <div>
                    <h3>Your Cities</h3>
                    <div className={styles.citySection}>
                        {loading ? (
                            <div className={styles.activityIndicatorContainer}>
                                <CircularProgress className={styles.activityIndicator}/>
                                <h6 className={styles.loadingMessage}>Loading cities...</h6>
                            </div>
                        ) : (
                            <>
                                {currentCity && (
                                    <div>
                                        <h6 className={styles.title}>Current City</h6>
                                        <div className={styles.city}>
                                            <div className={styles.iconTextContainer}>
                                                <LocationCityIcon fontSize="large"/>
                                                <div className={styles.cityDetails}>
                                                    <h6 className="primary">{currentCity.name}, {currentCity.zoneCode}</h6>
                                                    <h6 className={styles.radius}>{currentCity.radius} kilometers</h6>
                                                </div>
                                            </div>
                                            <h6
                                                className={styles.editRangeButton}
                                                onClick={() => handleEdit(-1)}
                                            >Edit City Range</h6>
                                        </div>
                                    </div>
                                )}
                                {cities.length > 0 &&
                                    <div>
                                        <h6 className={styles.title}>Other Cities</h6>
                                        {cities.map((city, index) => (
                                        <div className={styles.city} key={index}>
                                            <div className={styles.iconTextContainer}>
                                                <LocationCityIcon fontSize="large"/>
                                                <div className={styles.cityDetails}>
                                                    <h6>{city.name}, {city.zoneCode}</h6>
                                                    <h6 className={styles.radius}>{city.radius} kilometers</h6>
                                                </div>
                                            </div>
                                            <MoreHorizIcon
                                                fontSize="small"
                                                className={styles.moreIcon}
                                                onClick={(e) => toggleMenu(index, e)}
                                            />
                                            {menuVisible === index && (
                                                <div className={styles.menu} ref={el => menuRefs.current[index] = el}
                                                     onClick={(e) => e.stopPropagation()}>
                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleMakeCurrent(index);
                                                    }}><h6>Set as Current City</h6></div>
                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEdit(index);
                                                    }}><h6>Edit City Range</h6></div>
                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(index);
                                                    }}><h6>Remove City</h6></div>
                                                </div>
                                            )}
                                        </div>
                                        ))}
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.modalFooter}>
                    <div className={styles.addCity} onClick={handleAddNewCity}>
                        <AddLocationAltIcon className={styles.icon}/>
                        <h6>Add City</h6>
                    </div>
                    <div className={styles.actions}>
                        <div onClick={onClose}>
                            <h6>Done</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListOfCitiesModal;
