import '../style/App.scss';
import * as Store from "../backend/storage";
import * as loginApi from "../backend/loginApi";
import {LoginStrategy} from "../backend/loginApi";
import * as apiService from "../backend/apiService";
import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import ActivityIndicator from "../components/ActivityIndicator";
import EmailVerificationModal from "../components/EmailVerificationModal";
import ErrorModal from "../components/ErrorModal";
import {backendEndpoint, frontendEndpoint} from '../backend/axiosInstance';
import handleLogin from "../utils/handleLogin";
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import styles from '../style/pages/Login.module.scss';

const randomstring = require("randomstring")

const Login = () => {
    const navigate = useNavigate();

    const [spotifyButtonActive, setSpotifyButtonActive] = useState<boolean>(false);
    const [emailButtonActive, setEmailButtonActive] = useState<boolean>(false);
    const [guestButtonActive, setGuestButtonActive] = useState<boolean>(false);

    const [emailText, setEmailText] = useState<string>("");
    const [emailNonce, setEmailNonce] = useState<string>("");
    const [emailCode, setEmailCode] = useState<string>("");
    const [numTries, setNumTries] = useState<number>(0);
    const [verifyButtonActive, setVerifyButtonActive] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const spotifyLogin = async () => {
        setSpotifyButtonActive(true);
        let random = randomstring.generate({ length: 32, charset: "alphabetic" });
        await Store.storeSpotifySecret(random);
        const inputString = random + '_localify-auth';

        // Use the Web Crypto API to generate a SHA-256 hash
        const encoder = new TextEncoder();
        const data = encoder.encode(inputString);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(byte => String.fromCharCode(byte)).join('');
        const base64Hash = btoa(sha256Hash);

        // Make the base64-encoded hash URL-friendly by replacing characters
        const urlFriendlyHash = base64Hash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

        // Replace the following line with your actual Spotify URL
        window.location.href = `${backendEndpoint}v1/auth/spotify/login?redirect=${frontendEndpoint}spotify-login&state=${urlFriendlyHash}=`
    }

    // guest accounts never have pre-existing city information.
    const guestLogin = async () => {
        setGuestButtonActive(true);
        try {
            Store.clearAuthToken();
            const guestAuthToken = await loginApi.createGuestAccount();
            if (guestAuthToken.token) {
                await Store.storeTokenData(guestAuthToken);
                Store.storeUserLoginStrategy(LoginStrategy.GUEST);
                navigate("/onboarding/location")
            } else {
                console.log("Token is null")
            }
        } catch (error) {
            console.log("Error creating guest account")
            setErrorMessage("Error creating guest account. Please try again later.");
        } finally {
            setGuestButtonActive(false);
        }
    }

    const emailLogin = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailText)) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
            setEmailButtonActive(true);
            apiService.emailVerification(emailText).then((resp) => {
                console.log("Nonce: " + resp.nonce);
                setShowWarning(false);
                setEmailNonce(resp.nonce);
            }).catch(error => {
                setErrorMessage("Error creating email account. Please try again later.")
            }).finally(() => setEmailButtonActive(false))
        }
    }

    const submitEmailVerification = (value: string) => {
        setVerifyButtonActive(true);
        loginApi.handlePinEnter(emailNonce, value).then(async respToken => {
            if (respToken.token) {
                await Store.storeTokenData(respToken);
                Store.storeUserLoginStrategy(LoginStrategy.EMAIL);
                const nextPage = await handleLogin();
                navigate(nextPage);
            } else {
                throw new Error("Token is null")
            }
            setVerifyButtonActive(false);
        }).catch(error => {
            console.log("You failed: " + error)
            setEmailCode("");
            const newTries = numTries + 1;
            setNumTries(newTries);
            setVerifyButtonActive(false);
        })
    }

    const closeEmailModal = () => {
        setEmailCode("");
        setNumTries(0);
        setEmailText("");
        setEmailNonce("");
    }

    //TODO: Change UI when width <675
    return (
        <div className={"App-body"}>
            { errorMessage &&
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => setErrorMessage(undefined)}
                    onCloseMessage="Close"
                /> }
            <PageHeader type={HeaderTypes.LANDING} />
            <div className={styles["login-container"]}>
                <div className={styles["login-box"]}>
                    <h1 className={styles["login-title"]}>Sign In</h1>

                    <div style={{ marginTop: 25 }}>
                        <h1 className={styles["provider-text"]}>— Providers offer preselected artists —</h1>
                        <button
                            className={styles["spotify-button"]}
                            onClick={() => spotifyLogin()}
                        >
                            {!spotifyButtonActive ? `Continue with Spotify` :
                                <div style={{
                                    position: 'relative',
                                    top: '40%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <ActivityIndicator color="white" width={"20px"} height={"20px"}/>
                                </div>
                            }
                        </button>
                    </div>

                    <div className={styles["divider"]}>
                        <div className={styles["divider-line"]} />
                        <h1 className={styles["divider-text"]}>or</h1>
                        <div className={styles["divider-line"]} />
                    </div>

                    <div>
                        <input
                            className={styles["email-input"]}
                            placeholder={"Email Address"}
                            value={emailText}
                            onChange={(event) => setEmailText(event.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter")
                                    emailLogin();
                            }}
                        />
                        <button
                            className={styles["email-button"]}
                            onClick={() => emailLogin()}
                        >
                            {!emailButtonActive ? `Continue` : (
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <ActivityIndicator color="white" width={"20px"} height={"20px"}/>
                                </div>
                            )}
                        </button>
                        {showWarning &&
                            <h6 className={styles["warning-text"]}>
                                Please enter a valid email address
                            </h6>
                        }
                    </div>

                    <div className={styles["divider"]}>
                        <div className={styles["divider-line"]} />
                        <h1 className={styles["divider-text"]}>or</h1>
                        <div className={styles["divider-line"]} />
                    </div>

                    <button
                        className={styles["guest-button"]}
                        onClick={() => guestLogin()}
                    >
                        {!guestButtonActive ? `Sign In as Guest` : (
                            <div style={{
                                position: 'relative',
                                top: '40%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <ActivityIndicator color="white" width={"20px"} height={"20px"}/>
                            </div>
                        )}
                    </button>
                </div>
            </div>

            {emailNonce && (
                <div className={styles["modal-overlay"]}>
                    <div className={styles["modal-content"]}>
                        <EmailVerificationModal
                            value={emailCode}
                            setValue={setEmailCode}
                            email={emailText}
                            nonce={emailNonce}
                            onComplete={submitEmailVerification}
                            numTries={numTries}
                            verifyButtonActive={verifyButtonActive}
                            onClose={closeEmailModal}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Login;
