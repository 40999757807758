import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "../style/pages/NewChooseArtists.module.scss";
import chooseLocationStyles from "../style/pages/NewChooseLocation.module.scss";

import PageHeader, { HeaderTypes } from "../components/PageHeader";
import OnboardingBreadcrumb, { OnboardingStages } from "../components/OnboardingBreadcrumb";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import * as apiService from "../backend/apiService";
import { ArtistResponse, TopGenresResponse } from "../backend/apiService";
import ActivityIndicator from "../components/ActivityIndicator";
import ChooseGenreCard from "../components/ChooseGenreCard";
import SearchArtistModal from "../components/SearchArtistModal";
import globalColors from "../style/globalColors";


enum ErrorTypes {
    FAILED_TO_FETCH_GENRES = "Failed to fetch genres",
    FAILED_TO_SUBMIT_ARTISTS = "Failed to submit artists",
}

const ChooseArtists: React.FC = () => {
    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    const [currentOnboardingStage, setCurrentOnboardingStage] = useState<OnboardingStages>(OnboardingStages.GENRES);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [genres, setGenres] = useState<TopGenresResponse[] | undefined>(undefined);
    const [genresSelected, setGenresSelected] = useState<string[]>([]);
    const [artists, setArtists] = useState<ArtistResponse[]>([]);
    const [artistsSelected, setArtistsSelected] = useState<string[]>([]);
    const [searchAddedArtists, setSearchAddedArtists] = useState<ArtistResponse[]>([]);
    const [uploadingOnboardingInfo, setUploadingOnboardingInfo] = useState<boolean>(false);
    const [showSearchArtistsModal, setShowSearchArtistsModal] = useState<boolean>(false);
    const [allowCardSelections, setAllowCardSelections] = useState<boolean>(true);
    const [allowProceed, setAllowProceed] = useState<boolean>(false);
    const [errorType, setErrorType] = useState<ErrorTypes | undefined>();

    // Fetch genres from API
    const fetchGenres = () => {
        setLoading(true);
        apiService.fetchTopGenres()
            .then((response) => {
                setGenres(response);
                setLoading(false);
            })
            .catch(() => setErrorType(ErrorTypes.FAILED_TO_FETCH_GENRES));
    };

    useEffect(() => {
        document.body.style.overflow = "auto"; // Allows for scrolling
        fetchGenres(); // Fetch genres on component mount
    }, []);

    // Handle selection of genre cards
    const handleGenreCardSelect = (genreId: string) => {
        setGenresSelected((prevSelectedGenres) => {
            if (prevSelectedGenres.includes(genreId)) {
                return prevSelectedGenres.filter((id) => id !== genreId);
            } else {
                return [...prevSelectedGenres, genreId];
            }
        });
    };

    // Handle selection of artist cards
    const handleArtistCardSelect = (artistId: string) => {
        setArtistsSelected((prevSelectedArtists) => {
            if (prevSelectedArtists.find((id) => id === artistId)) {
                return prevSelectedArtists.filter((id) => id !== artistId);
            } else {
                return [...prevSelectedArtists, artistId];
            }
        });
    };

    // Manage proceeding logic based on selected genres and artists
    useEffect(() => {
        if (currentOnboardingStage === OnboardingStages.GENRES) {
            setAllowProceed(genresSelected.length >= 3 && genresSelected.length <= 5);
            setAllowCardSelections(genresSelected.length < 5);
        }
    }, [currentOnboardingStage, genresSelected]);

    useEffect(() => {
        if (currentOnboardingStage === OnboardingStages.ARTISTS) {
            setAllowProceed(artistsSelected.length >= 5);
        }
    }, [currentOnboardingStage, artistsSelected]);

    // Handle submission of genres to fetch related artists
    const handleGenresSubmit = () => {
        const selectedArtists = genresSelected.reduce<ArtistResponse[]>((allArtists, genreId) => {
            const genre = genres?.find((g) => g.id === genreId);
            if (genre) {
                const newArtists = genre.topArtists.filter(
                    (artist) => !allArtists.some((existingArtist) => existingArtist.id === artist.id)
                );
                return allArtists.concat(newArtists);
            }
            return allArtists;
        }, []);

        // Shuffle selected artists
        selectedArtists.sort(() => Math.random() - 0.5);
        setArtists(selectedArtists);

        // Update onboarding stage
        setCurrentOnboardingStage(OnboardingStages.ARTISTS);
        setLoading(false);
        setAllowCardSelections(true);
        setAllowProceed(false);
        setGenresSelected([]);
    };

    // Handle submission of selected artists
    const handleArtistsSubmit = () => {
        setUploadingOnboardingInfo(true);

        apiService.putUserSeeds(artistsSelected).then(() => {
            navigate('/recommendations', {replace: true});
            setUploadingOnboardingInfo(false);
        }).catch(() => setErrorType(ErrorTypes.FAILED_TO_SUBMIT_ARTISTS));
    };

    // Handle proceeding based on current stage
    const handleProceeding = () => {
        if (allowProceed) {
            if (currentOnboardingStage === OnboardingStages.GENRES) {
                setLoading(true);
                handleGenresSubmit();
            } else if (currentOnboardingStage === OnboardingStages.ARTISTS) {
                handleArtistsSubmit();
            }
        }
    };

    return (
        <div className={styles["App-body"]}>
            <PageHeader type={HeaderTypes.ONBOARDING} />
            <section className={styles["onboarding-header"]}>
                <button
                    className={styles["back-button"]}
                    onClick={() =>
                        currentOnboardingStage === OnboardingStages.ARTISTS
                            ? setCurrentOnboardingStage(OnboardingStages.GENRES)
                            : navigate(-1)
                    }
                >
                    <ArrowBackRoundedIcon />
                </button>
                <div className={styles["header-text"]}>
                    <h1 className={styles["header-title"]}>
                        {currentOnboardingStage === OnboardingStages.GENRES
                            ? "What kinds of music do you like?"
                            : "Who are you currently listening to?"}
                    </h1>
                    {currentOnboardingStage === OnboardingStages.GENRES ? (
                        <p className={styles["header-subtitle"]}>
                            Choose at least 3 options <span className={styles["max-num"]}>(max. 5)</span>
                            <br />
                            <span className={styles["selected-num"]}>{genresSelected.length} selected</span>
                        </p>
                    ) : (
                        <>
                            <p className={styles["header-subtitle"]}>
                                Choose as many artists as you want <span className={styles["max-num"]}>(min. 5)</span>
                                <br />
                                <span className={styles["selected-num"]}>{artistsSelected.length} selected</span>
                            </p>
                            {/* TODO: Move button styling to SCSS style file */}
                            <button
                                style={{
                                    backgroundColor: globalColors.primary,
                                    border: "none",
                                    borderRadius: 25,
                                    padding: "8px 20px",
                                    display: "inline-block",
                                    cursor: "pointer",
                                    marginTop: 5,
                                    color: "white",
                                }}
                                onClick={() => setShowSearchArtistsModal(true)}
                            >
                                Search All Artists
                            </button>
                        </>
                    )}
                </div>
                {showSearchArtistsModal && (
                    <SearchArtistModal
                        onArtistClick={(artistID, artistSlug, artist) => {
                            if (artist) {
                                handleArtistCardSelect(artistID);
                                if (!artists.includes(artist))
                                    setArtists((prev) => [artist, ...prev]);
                            }
                        }}
                        onClose={() => setShowSearchArtistsModal(false)}
                        selectedArtistsIDs={artistsSelected}
                    />
                )}
                <button
                    className={`${styles["next-button"]} ${allowProceed ? styles["active"] : ""}`}
                    onClick={handleProceeding}
                >
                    Continue <ArrowForwardRoundedIcon sx={{ fontSize: 25 }} />
                </button>
            </section>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                    }}
                >
                    <ActivityIndicator />
                </div>
            ) : (
                !showSearchArtistsModal && (<div className={styles["genres-section"]}>
                    {currentOnboardingStage === OnboardingStages.GENRES ? (
                        genres && genres.map((genre, i) => {
                                const artistList = genre.topArtists.slice(0,3).map(
                                    ((artist, index) => (index !== 0 ? ", " : "") + artist.name))
                                return(
                                    <div
                                        key={i}
                                        onClick={() => handleGenreCardSelect(genre.id)}
                                        className={`${styles["genres-card"]} ${genresSelected.includes(genre.id) ? styles["active"] : ""}`}
                                    >
                                        <h3>{genre.name}</h3>
                                        {/*<h5>Popular Artists:</h5>*/}
                                        <h6>{artistList}</h6>
                                    </div>
                                )
                            })) : (
                        <>
                            {artists && artists.map((artist, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleArtistCardSelect(artist.id)}
                                        className={`${styles["artist-card"]} ${artistsSelected.includes(artist.id) ? styles["active"] : ""}`}
                                    >
                                        <img alt="" src={artist.image || noImage} />
                                        <h6>{artist.name}</h6>
                                    </div>
                                ))}
                        </>
                    )}
                </div>))}
            <section className={`${chooseLocationStyles["breadcrumb-section"]} ${styles["breadcrumb-background"]}`}>
                <OnboardingBreadcrumb
                    onboardingStage={currentOnboardingStage}
                    goBackGenres={() => setCurrentOnboardingStage(OnboardingStages.GENRES)}
                    allowTravelArtists={genresSelected.length > 2}
                    handleArtistButtonClicked={handleProceeding}
                />
            </section>
        </div>
    );
};

export default ChooseArtists;
