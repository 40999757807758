import React, { useState, useEffect, useRef } from 'react';
import styles from "../style/components/ArtistCityModals.module.scss";
import * as apiService from "../backend/apiService";
import { ArtistResponse, CityResponse } from "../backend/apiService";
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { getUserLoginStrategy } from "../backend/storage";
import { LoginStrategy } from "../backend/loginApi";
import { useNavigate } from "react-router-dom";

type Props = {
    city: CityResponse;
    onClose: () => void;
};

const AddArtistsToCityModal: React.FC<Props> = ({ city, onClose }) => {
    const navigate = useNavigate();
    const [artists, setArtists] = useState<ArtistResponse[]>([]);
    const [selected, setSelected] = useState<ArtistResponse[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [continueClicked, setContinueClicked] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [artistsSubmitted, setArtistsSubmitted] = useState<boolean>(false);

    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';
    const inputRef = useRef<HTMLInputElement>(null);
    const isGuest = getUserLoginStrategy() === LoginStrategy.GUEST;

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const fetchData = async (query: string) => {
        setLoading(true);
        try {
            const response = await apiService.fetchSearchArtists(query);
            setArtists(response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (inputValue.trim()) {
            fetchData(inputValue).then();
        } else {
            setArtists([]);
        }
    }, [inputValue]);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleClick = (artist: ArtistResponse) => {
        if (!continueClicked) {
            setSelected(prevSelected =>
                prevSelected.some(selectedArtist => selectedArtist.id === artist.id)
                    ? prevSelected.filter(selectedArtist => selectedArtist.id !== artist.id)
                    : [...prevSelected, artist]
            );
        }
    };

    const handleSubmit = async () => {
        setSubmitLoading(true);
        try {
            await Promise.all(selected.map(artist => apiService.addCityForArtist(artist.id, city.id)));
            setArtistsSubmitted(true);
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitLoading(false);
        }
    };

    const renderArtistGrid = (artistsToRender: ArtistResponse[], isClickable: boolean) => (
        <div className={styles["artist-grid"]}>
            {artistsToRender.map((artist, index) => {
                const isSelected = selected.some(selectedArtist => selectedArtist.id === artist.id);
                return (
                    <div
                        key={index}
                        onClick={() => isClickable && handleClick(artist)}
                        className={`${styles["artist-element"]} ${isSelected && isClickable ? styles["active"] : ''}`}
                        style={{ cursor: isClickable ? 'pointer' : 'default' }}
                    >
                        <img src={artist.image || noImage} alt={artist.name} />
                        <h6>{artist.name}</h6>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className={styles["modal-overlay"]} onClick={onClose}>
            <div className={styles["modal"]} onClick={e => e.stopPropagation()}>
                {isGuest ? (
                    <div className={styles["text-container"]}>
                        <div className={styles["thank-you-message"]}>
                            <h2 className={styles["title"]}>Please Login to Contribute</h2>
                            <p>
                                For the sake of maintaining accurate information,
                                Localify does not permit guest users to contribute to artist or city profiles.
                                If you would like to help us, which we greatly appreciate, please sign in using
                                a different method.
                            </p>
                        </div>
                    </div>
                ) : !artistsSubmitted ? (
                    <div className={styles["text-container"]}>
                        <h3 className={styles["title"]}>
                            {`Add Artists to ${city.name}, ${city.zoneCode || city.countryCode}`}
                        </h3>
                        {!continueClicked ? (
                            <div className={styles['input-wrapper']}>
                                <SearchIcon className={styles['input-icon']} style={{ fontSize: '0.75em' }} />
                                <input
                                    ref={inputRef}
                                    className={styles['input']}
                                    placeholder="Search Artists"
                                    value={inputValue}
                                    onChange={onTextChange}
                                />
                                {loading && <CircularProgress className={styles['loading-indicator']} size={24} thickness={5} />}
                            </div>
                        ) : (
                            <p className={styles["subtitle"]}>
                                {`Localify relies on your suggestions for artist origins. Before submitting, are you sure
                                that the following ${selected.length === 1 ? "artist is" : "artists are"} 
                                local to ${city.name}, ${city.zoneCode || city.countryCode}?`}
                            </p>
                        )}
                        {!continueClicked ? renderArtistGrid(artists, true) : renderArtistGrid(selected, false)}
                    </div>
                ) : (
                    <div className={styles["thank-you-message"]}>
                        <h2>Thank you for your submission!</h2>
                        <p>
                            Localify truly appreciates your contribution to the local music community. If you don't see
                            your submission right away, don't worry, your artist origin suggestions are most likely under
                            review by our team. Thanks again!
                        </p>
                    </div>
                )}
                <div className={styles["buttons"]}>
                    {!artistsSubmitted ? (
                        <h6 onClick={onClose}>{!continueClicked ? "Cancel" : "No, Cancel"}</h6>
                    ) : (
                        <h6 style={{ borderColor: "transparent" }}></h6>
                    )}
                    {isGuest ? (
                        <h6
                            className={styles["active"]}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate("/user/settings");
                            }}
                        >
                            Settings
                        </h6>
                    ) : !continueClicked ? (
                        <h6
                            onClick={(e) => {
                                e.stopPropagation();
                                setContinueClicked(true);
                            }}
                            className={selected.length > 0 ? styles["active"] : ""}
                        >
                            Continue
                        </h6>
                    ) : !artistsSubmitted ? (
                        <h6
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSubmit().then();
                            }}
                            className={styles["active"]}
                        >
                            {submitLoading ? <CircularProgress className={styles["submit-loading"]} /> : 'Yes, Submit'}
                        </h6>
                    ) : (
                        <h6 onClick={onClose} className={styles["active"]}>Done</h6>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddArtistsToCityModal;
